import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap, skip, map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";

import { environment } from "src/environments/environment";
import { Register } from "src/app/shared";
const credentialsKey = "currentUser";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public isSaveAndExit = new BehaviorSubject({
    isSaveAndExit: false,
    currentForm: null,
  });

  public isResubmit = new BehaviorSubject({
    isResubmit: false,
    currentForm: null,
  });

  public userDetails = new BehaviorSubject(null);
  public userDetailsObs = this.userDetails.asObservable();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  registerUser(data: Register): Observable<any> {
    const href = `${environment.register}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          this.authenticationService.setUser(data.response);
        }
        return data;
      })
    );
  }

  updateUserProfile(payload): Observable<any> {
    const href = `${environment.updateProfile}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  document(payload): Observable<any> {
    const href = `${environment.document}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }
  documentDelete(payload): Observable<any> {
    const href = `${environment.deleteDocument}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  userDocumentDelete(payload): Observable<any> {
    const href = `${environment.deleteDocument}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  applicationSubmit(payload): Observable<any> {
    const href = `${environment.applicationSubmit}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  setCurrentApplication(application) {
    sessionStorage.setItem("application", JSON.stringify(application));
  }

  removeApplication() {
    sessionStorage.removeItem("application");
  }

  getCurrentApplication() {
    return sessionStorage.getItem("application")
      ? JSON.parse(sessionStorage.getItem("application")) || null
      : null;
  }

  checkSaveAndExit(): Observable<any> {
    return this.isSaveAndExit.asObservable().pipe(skip(1));
  }

  checkResubmit(): Observable<any> {
    return this.isResubmit.asObservable().pipe(skip(1));
  }

  getApplicationList(pagination): Observable<any> {
    const href = environment.getApplicationList;
    return this.http.get<any>(href, { params: pagination }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getApplicationById(id): Observable<any> {
    const href = `${environment.getApplicationById}`;
    return this.http.get<any>(href, { params: { application_id: id } }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getCityList(): Observable<any> {
    const href = environment["getCityList"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  setSelectedCity(id) {
    sessionStorage.setItem("selectedCity", id);
  }

  getSelectedCity() {
    return sessionStorage.getItem("selectedCity");
  }

  removeSelectedCity() {
    sessionStorage.removeItem("selectedCity");
  }

  setLicenseTypeForSelectedCity(licenseType) {
    sessionStorage.setItem("licenseType", licenseType);
  }

  getLicenseTypeForSelectedCity() {
    return JSON.parse(sessionStorage.getItem("licenseType"));
  }

  removeLicenseTypeForSelectedCity() {
    sessionStorage.removeItem("licenseType");
  }
  verifiyOtpProfile(data) {
    const href = environment.verifiyOtpProfile;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status === "success") {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(res.response));
        }
        if (res.status == "success") {
          return res;
        }
      })
    );
  }
  sendProfileOtp(payload) {
    const href = environment.sendProfileOtp;
    return this.http.get<any>(href, { params: payload }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  noFireFeeSubmit(data): Observable<any> {
    const href = `${environment.noFireFeeSubmit}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }
  renewPermit(payload: any): Observable<any> {
    const href = `${environment.renewPermit}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
}
