/**
 * API URLS
 */
export const baseUrl = 'https://tw.appening.xyz/'
export const urls = {
  login: "auth/api/login",
  register: "auth/api/register",
  updateProfile: "api/user/profile",
  document: "api/application/document",
  deleteDocument: "api/application/document/delete",
  sendPasswordLink: "auth/api/forget-password/send-email",
  resetPassword: "auth/api/forget/create-password",
  adminLogin: "auth/api/admin/login",
  createStaffPassword: "auth/api/staff/create-password",
  resetStaffPassword: "auth/api/staff/reset-password",
  sendPasswordLinkStaff: "auth/api/staff/create-password/send-email",
  sendResetPasswordLinkStaff: "auth/api/staff/reset-password/send-email",
  sendProfileOtp: "api/user/send-profile-phone-otp",
  verifiyOtpProfile: "api/user/verify-profile-otp",
  emailLinkVerificationadmin: "auth/api/validate-admin-email-link",
  application: "api/application",
  getApplicationList: "api/applications",
  getApplicationById: "api/applicationById",
  addACHAccount: "api/ach-account",
  verifyACHAccount: "api/ach-account/verify",
  verifyCreditCard: "api/ach-account/card/verify",
  deleteACHAccount: "api/ach-account/delete",
  getACHAccount: "api/ach-account",
  achAccountList: "api/ach-account",
  achAccountListIndex: "api/ach-account-index",
  getApplicationFee: "api/application/fee",
  applicationPayment: "api/application-payment",
  noFireFeeSubmit: "api/application/submit",
  searchAddress: "api/city/address/",
  getCityList: "api/cities",
  downloadPermit: "api/admin/download-certificate/",
  deleteOwnerOfficer: "api/ownership/remove",
  editOwnerOfficer: "api/ownership/update",
  // applicationResubmit: 'api/application/resubmit',
  applicationResubmit: "api/application-resubmit",
  editApplication: "api/application",
  applicationAdditionalInfo: "api/application-form",
  applicationAdditionalInfoDocUpload: "api/application-form/document",
  getApplicationAdditionalInfo: "api/application-form",
  additionalInfoDocDelete: "api/application-form/delete",
  timeline: "api/application-timeline",
  getCSRFToken: "api/csrf-token",
  verifyCSRFToken: `api/verify-csrf-token`,

  getAdminApplication: "api/admin/applications",
  getCityAdminList: "api/admin/city-admin",
  addCityAdmin: "api/admin/city-admin",
  saveAddress: "api/admin/upload/address",
  addStaffByCityAdmin: "api/admin/staff",
  getCityAdminStaffList: "api/admin/staff",
  updateCityAdmin: "api/admin/city-admin",
  adminApplicationById: "api/admin/application/",
  postSubmissionReview: "api/admin/clerk-submission-review",
  voidSubmissionReview: "api/admin/clerk-submission-review/void",
  applicationUpdateByAdmin: "api/admin/application/update",
  postDecision: "api/admin/decision",
  voidDecision: "api/admin/decision/void",
  submitNotes: "api/admin/notes",
  postEmail: "api/admin/send-mail",
  deleteNote: "api/admin/notes/delete",
  postPayment: "api/admin/add/fee",
  manger: "api/admin/city/manager",
  inspector: "api/admin/city/inspector",
  waiveOffFee: "api/admin/waive-off/fee",
  managerProfileUploadSignature: "api/admin/city-admin/profile",
  cityAdminStaffResetPassword: "api/admin/staff/password/create",
  addLicenseFee: "api/admin/add-license-fee",
  updateLicenseFee: "api/admin/update-license-fee",
  deleteLicenseFee: "api/admin/remove-license-fee",
  adminTimeline: "api/admin/application-timeline",
  deleteOwnerOfficerAdmin: "api/admin/ownership/remove",
  editOwnerOfficerAdmin: "api/admin/ownership/update",
  inspectionAdd: "api/admin/inspection",
  // assignInspector:'api/admin/assign-inspector',
  assignInspector: "api/admin/assign-schedule-inspection",

  inspectionVoid: "api/admin/inspection/void",
  getCityAdminById: "api/admin/staff-details",
  sendApplicationAdditionalInfoAdmin: "api/admin/send-application-form-link",
  documentAdmin: "api/admin/application/document",
  deleteDocumentAdmin: "api/admin/application/document/delete",
  applicationSubmit: "api/application-submit",
  scheduleInspector: "api/admin/schedule-inspection",
  verifyCSRFTokenAdmin: `api/admin/verify-csrf-token`,
  sendOtpProfile: "api/admin/send-profile-phone-otp",
  verifiyOtpProfileadmin: "api/admin/verify-profile-otp",
  emailResendVerificationadmin: "api/admin/resend-email-link",
  accpetInspection: "api/admin/inspection/decision-change",
  updateInspection: "api/admin/update-inspection",
  downloadPdf: "api/admin/download-trade-waste-certificate",
  renewPermit:'api/renew-permit',

};
